import {Injectable} from '@angular/core';
import {SaveVersionValidationModel} from "./save-version-validation-model";
import * as moment from "moment";
import {MessageService} from 'abp-ng2-module';
import {AppLocalizationService} from '../localization/app-localization.service';

@Injectable()
export class SaveVersionService {

    constructor(
        private localization: AppLocalizationService,
        private message: MessageService
    ) {
    }

    // checks the new validSince date to be < the second (one after initial) versionDate (if any)
    checkValidSinceDate(saveVersionValidationModel: SaveVersionValidationModel): boolean {

        if (saveVersionValidationModel.versionDatesStrings.length > 1) {
            if (saveVersionValidationModel.validSince >=
                moment(saveVersionValidationModel.versionDatesStrings[
                saveVersionValidationModel.versionDatesStrings.length - 2], 'L')) {
                return false;
            }
        }

        return true;
    }

    // checks the new validThrough date to be >= the most recent (newest) versionDate
    checkValidThroughDate(saveVersionValidationModel: SaveVersionValidationModel): boolean {

        if (saveVersionValidationModel.validThrough == null) {
            return true;
        }

        if (saveVersionValidationModel.validThrough < saveVersionValidationModel.validSince) {
            let msg = this.localization.l('ValidThroughMustBeGreaterOrEqualToValidSince');
            this.message.warn(msg);
            return false;
        }

        if (saveVersionValidationModel.versionDatesStrings.length > 0) {
            let verDate = moment(saveVersionValidationModel.versionDatesStrings[0], moment.ISO_8601);
            if (saveVersionValidationModel.validThrough < verDate) {
                let msg = this.localization.l('ValidThroughMustBeGreaterOrEqualToNewestVersionDate');
                this.message.warn(msg);
                return false;
            }
        }

        return true;
    }

    // checks: 1) new versionDate to be >= validSince date
    //         2) new versionDate to be <= validThrough date (if any)
    //         3) new versionDate to be != of any of the version dates
    validateDates(saveVersionValidationModel: SaveVersionValidationModel,
                  versionDate: moment.Moment): boolean {

        let msg = '';

        if (versionDate < saveVersionValidationModel.validSince) {
            msg = this.localization.l('VersionDateBeforeValidSinceDate');
            this.message.warn(msg);

            return false;
        } else if (saveVersionValidationModel.validThrough != null &&
            versionDate > saveVersionValidationModel.validThrough
        ) {
            msg = this.localization.l('VersionDateAfterValidThroughDate');
            this.message.warn(msg);

            return false;
        } else {
            for (let idx = 0; idx < saveVersionValidationModel.versionDatesStrings.length; idx++) {
                if (versionDate.format('L') == moment(saveVersionValidationModel.versionDatesStrings[idx]).format('L')) {
                    msg = this.localization.l('VersionDateOccupied');
                    this.message.warn(msg);

                    return false;
                }
            }
        }

        return true;
    }

    validateVersionDate(saveVersionValidationModel: SaveVersionValidationModel,
        versionDate: moment.Moment): boolean {

        let msg = '';

        if (versionDate < saveVersionValidationModel.validSince) {
            msg = this.localization.l('VersionDateBeforeValidSinceDate');
            this.message.warn(msg);

            return false;
        } else if (saveVersionValidationModel.validThrough != null 
            && versionDate > saveVersionValidationModel.validThrough) {
            msg = this.localization.l('VersionDateAfterValidThroughDate');
            this.message.warn(msg);

            return false;
        } 

        return true;
    }

    validateValidSinceDate(saveVersionValidationModel: SaveVersionValidationModel,
        validSinceParent: moment.Moment): boolean {

        let msg = '';

        if (validSinceParent && validSinceParent > saveVersionValidationModel.validSince) {
            msg = this.localization.l('ParentValidSinceDateBeforeValidSinceDate');
            this.message.warn(msg);

            return false;
        } 

        return true;
    }
}
